.language-selector {
    margin-right: 16px;
    border-right: 1px solid var(--borderColor);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: fit-content;
    column-gap: 8px;
    position: relative;
    border-left: 1px solid var(--borderColor);
    cursor: pointer;
}

.chevron_down{ 
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.chevron_up{
    transition: all 0.5s ease;
}

.dropdown_list {
    position: absolute;
    box-sizing: border-box;
    width: 160px;
    height: fit-content;
    background-color: white;
    top: 80%;
    right: 16px;
}

.display_language {
    width: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: black;
}
