.property_item {
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 2rem;
}
.header {
    height : 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.header .left_sec {
    display: flex;
}

.chevron_open{
    display: inline-block;
    padding-right: 16px;
    transition: all 0.5s ease;
}

.chevron_close {
    display: inline-block;
    padding-left: 16px;
    transform: rotate(180deg);
    transition: all 0.5s ease;
}
.id {
    padding-right: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}
.property_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: black;
}

.right_sec {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    column-gap: 1rem;
    height: 100%;
    width: fit-content;
    position: relative;
    align-items: center;
}
.hidden_sec {
    height: fit-content;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin-left: 40px;
    margin-top: 12px;
}
.select_all {
    height: 24px;
    width: 100%;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    margin : 0 0 12px 0;
    padding: 0 1rem;
}

.select_all label {
    box-sizing: border-box;
    padding: 0 12px;
}
.task_list {
    border: 1px solid var(--borderColor);
    border-radius: 6px;
    background-color: var(--borderColor);
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    overflow: hidden;
}

.no_task {
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 8px 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--buttonRed);
}

.list {
    position: absolute;   
    z-index: 10;
    box-sizing: border-box;
    width: 240px;
    height: fit-content;
    top: 30px;
    right: -8px;
}

.dates {
    width: 100%;
    background-color: #F2F2F2;
    padding: 8px 0;
    padding-left: 50px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
}