.footer {
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    position: fixed;
    bottom: 0;
    left: 15.5rem;
    height: 10vh;
    box-sizing: border-box;
    border-top: 1px solid var(--borderColor);
    display: flex;
    padding: 0 32px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.left_sec {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    column-gap: 8px;
    width: fit-content;
    align-items: center;
}

.size_selector {
    height: 34px;
    width: fit-content;
    display: flex;
    padding: 4px 8px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    color: var(--blackHigh);
    position: relative;
    cursor: pointer;
}

.display_size {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: fit-content;
}

.chevron_open{
    transition: all 0.5s ease;
}

.chevron_close {
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.dropdown {
    width: max-content;
    max-height: 160px;
    overflow : scroll;
    position: absolute;
    bottom: 40px;
    left : 0;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    z-index: 100;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.row{
    width: fit-content;
    padding: 4px 16px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.option {
    max-height: 33px;
    display: flex;
    flex-direction: column;
}

.option:hover {
    background-color: #F8F8F8;
}

.divider {
    width: 150px;
    height: 1px;
    
}

.right_sec {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
}

.middle_sec{
    height: 32px;
    display: flex;
    column-gap: 16px;
    width: fit-content;
}

.chevron {
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.block {
    width: 32px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 4px;
    cursor: pointer;  
}

.block:hover {
    background-color: #F8F8F8;
}

.selected {
    width: 32px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background-color: #2298DA;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;  
    border-radius: 4px;
    cursor: pointer;
}

.pagination_container {
    display: flex;
}