.task_box{
    display: flex;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 8px 1rem;
    justify-content: space-between;
    align-items: flex-start;
}
.left_sec {
    display: flex;
    width: max-content;
    height: fit-content;
    align-items: flex-start;
    padding : 4px 0;
}

.task_priority {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #DF9641;
    height: 24px;
    box-sizing: border-box;
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.task_priority p {
    margin: 0;
}

.task_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    padding: 0 12px;
}
.task_description {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 4px;
}
.right_sec {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    column-gap: 12px;
}
.right_sec > img {
    display: inline-block;
    padding: 4px 0;
    cursor: pointer;
}
.assign_staff {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--linkBlue);
    cursor: pointer;
}
.staff_list {
    display: flex;
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    flex-flow: row-reverse wrap;
    row-gap: 12px;
}
.padding_left {
    padding-left: 10px;
}