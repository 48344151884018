.group {
    height: fit-content;
    width: 504px;
    box-sizing: border-box;
    margin-bottom: 12px;
}

.display {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.left_sec {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
}

.large_font {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}
.small_font {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 10px;
}

.chevron_close {
    transform: rotate(180deg);
  
}