.add_staff {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    height: 100%;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    background-color: white;
    z-index: 20;
}

.main_content {
    position: absolute;
    top: 12vh;
    display: flex;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 60px;
}

.form {
    width: 770px;
    height: fit-content;
    box-sizing: border-box;
    padding: 32px 0;
}

.subheader {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.35px;
    color: #000000;
    height: fit-content;
    width: 100%;
    padding-bottom: 32px;
}

.flex_container {
    height: fit-content;
    width: 100%;
    display: flex;
    row-gap: 32px;
    column-gap: 30px;
    flex-wrap: wrap;
}