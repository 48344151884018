.task_summary_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.main_content {
    position: absolute;
    top: 20vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    height: fit-content;
    display: flex;
    background-color: #F2F2F2;
    column-gap: 12px;
    overflow: auto;
}

.col1{
    height: 80vh;
    width: 790px;
    overflow: scroll;
    background-color: white;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.listing_header {
    box-sizing: border-box;
    padding: 1rem 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: 0.4px;   
}

.col2 {
    height: 80vh;
    width: 390px;
    background-color: white;
    box-sizing: border-box;
    padding: 0 30px;
    overflow: scroll;
}

.sub_header {
    display: flex;
    justify-content: space-between;
    height: 24px;
    width: 100%;
    padding: 16px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

.sub_header p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    margin: 0;
}