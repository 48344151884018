
.calendar{
    position: relative;
    margin-right: 4.5rem;
}
.display {
    height: 2.5rem;
    box-sizing: border-box;
    padding: 8px;
    width: fit-content;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--blackHigh);
    border: 1px solid rgba(34, 152, 218, 0.2);
    border-radius: 6px;
    cursor: pointer;
}
.display > div {
    padding: 0 1rem;
}

.date_picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 408px;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 24px;
    position: absolute;
    top: 3rem;
    left: -117px;
    background-color: white;
    z-index: 10;
}

.header{
    height: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    width: 360px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.header > div {
    padding: 0 10px;
    cursor: default;
}

.table_header{
    height: 48px;
    width: 360px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--borderColor);
}

.day_names {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 50px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 16px;
}
.table {
    height: fit-content;
    width: 360px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.row {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
}

.table_block{
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    padding-top: 16px;
    padding-left: 5px;
    padding-right: 5px;
}
.date {
    height: 50px;
    width: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--blackHigh);
    text-align: center;
    box-sizing: border-box;
    padding: 8px 0;
    cursor: pointer;
    padding-top: 12px;
}

.date:hover {
    border-radius: 50%;
    background-color: lightgray;
}

.selected {
    height: 50px;
    width: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background-color: var(--linkBlue);
    color: white;
    text-align: center;
    box-sizing: border-box;
    padding: 8px 0;
    border-radius: 50%;
    cursor: pointer;
    padding-top: 12px;
}

.selected_range {
    height: 50px;
    width: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: rgba(34, 152, 218, 0.08);
    box-sizing: border-box;
    padding: 8px 0;
    text-align: center;
    padding-top: 12px;
}

.blocked {
    height: 40px;
    width: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
    box-sizing: border-box;
    padding: 8px 0;
    cursor: default;
    padding-top: 12px;
}

.start{    
    width: 20px;
    height: 50px;
    background: rgba(34, 152, 218, 0.08);
    position: absolute;
    transform: translate(30px, -50px);
}

.end{    
    width: 20px;
    height: 50px;
    background: rgba(34, 152, 218, 0.08);
    position: absolute;
    transform: translate(0px, -50px);
}