.filter {
    width: fit-content;
    height: 32px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.block {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color : var(--blackHigh);
    padding: 4px 12px;
    width: fit-content;
    display: flex;
    column-gap: 8px;
    cursor: pointer;
}

.divider {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    height: 100%;
    margin: 0 20px;
    position: relative;
}

.connector{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1px 5px;
    height: 20px;
    width: 40px;
    font-weight: 600;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.4px;
    top: calc(50% - 20px/2);
    left: calc(50% - 20px);
    position: absolute;
}

.property_filter {
    position: absolute;
    top: 40px;
    right: -160px;
    z-index: 50;
}
.chevron_open{
    transition: all 0.5s ease;
}

.chevron_close {
    transform: rotate(180deg);
    transition: all 0.5s ease;
}
.distance_filter {
    position: absolute;
    top: 40px;
    left: -20px;
    z-index: 50;
}