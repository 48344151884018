.employee {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    box-sizing: border-box;
    row-gap: 1rem;
}

.header {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.left_sec {
    width: fit-content;
    height: fit-content;
    display: flex;
    column-gap: 8px;
    align-items: center;
    max-width: 70%;
}

.chevron_open{
    transition: all 0.5s ease;
}

.chevron_close {
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.employee_name{
    padding-right: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
    max-width: 50%;
}
.task_no {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
}

.right_sec {
    width: fit-content;
    display: flex;
    column-gap: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
}

.list {
    position: absolute;   
    z-index: 10;
    box-sizing: border-box;
    width: 240px;
    height: fit-content;
    top: 30px;
    right: -8px;
}


.hidden_sec {
    height: fit-content;
    width: calc(100% - 32px);
    box-sizing: border-box;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}