.header {
    height: 12vh;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
    position: fixed;
    background-color: white;
    z-index: 15;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
}

.left_sec{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #000000;
    display: flex;
}
.left_sec p {
    box-sizing: border-box;
    margin: 0 16px;
}