
.assign_staff {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 480px;
    width: 576px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: space-between;
}

.crossmark {
    position: absolute;
    top: 0;
    right: -48px;
    cursor: pointer;
}
.header {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 36px;
    border-bottom: 1px solid var(--borderColor);
}

.header .row1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #000000;
    padding-bottom: 4px;
}

.header .row2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
}
.footer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 36px;
    justify-content: space-between;
    border-top: 1px solid var(--borderColor);
    background-color: white;
    position: relative;
    z-index: 2;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.middle {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 36px;
    height: 342px;
    overflow: auto;
}