.list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.rows {
    width: 100%;
    color: #000000;
    padding: 8px 16px;
    list-style-type: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    box-sizing: border-box;
}

.rows:hover {
    background-color: #F8F8F8;
}

.divider {
    width: 100%;
}