
.block {
    box-sizing: border-box;
    width: 100%;
    padding: 24px 0;
    height: fit-content;
}

.sub_block {
    box-sizing: border-box;
    width: 25%;
}

.key {
    width: 100%;
    margin: 0;
    padding-bottom: 4px;
    box-sizing: border-box;
    height: fit-content;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
}
.value {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    height: fit-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}
.details_sec {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 8px 32px;
    display: flex;
    flex-direction: column;
}

.sub_heading {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.35px;
    color: #212121;
    padding-bottom: 14px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
}

.status {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 8px;
}