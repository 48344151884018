.App {
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  height: 100%;
  position: relative;
  cursor: default;
}
.mar-tb-10 {
  margin: 10px 0 !important;
}

.pad-12 {
  padding:12px !important;
}