.editinfo_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.main_content {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    height: 92vh;
    background-color: white;
    overflow: scroll;
    
}

.header {
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    justify-content: space-between;
}
.left_sec{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #000000;
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.right_sec{
    height: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    column-gap: 16px;
}

.edit_sec {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.block {
    width : 770px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    padding: 32px 0;
    row-gap: 32px;
    column-gap: 30px;
}
.subheading {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.35px;
    color: #000000;
}