.add_task {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    height: 100%;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    background-color: white;
    z-index: 20;
}


.add_task_form {
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 100%;
    margin-top: 12vh;
    padding-bottom: 20%;
}

.block {
    width : 770px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    padding: 32px 0;
    row-gap: 32px;
    column-gap: 30px;
}

.subheading {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.35px;
    color: #000000;
}