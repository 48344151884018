.error_page {
    position: absolute;
    bottom: 0;
    height: 92vh;
    right: 0;
    width: calc(100% - 15.5rem);
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error_title {
    margin: 32px 0 16px 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}