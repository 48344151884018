.scheduler_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.scheduler_listing {

    position: absolute;
    top: 20vh;
    left: 15.5rem;
    box-sizing: border-box;
    padding: 0 2rem;
    width: calc(100% - 15.5rem);
    height: 70vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.listing_header {
    box-sizing: border-box;
    padding: 1rem 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    
}

.filter_sec{
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    display: flex;
    column-gap: 16px;
    align-items: center;
    padding: 12px 0;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    left: 15.5rem;
    background-color: white;
    z-index: 5;
}