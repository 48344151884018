.property_block {
    display: flex;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 12px 0;
    flex-direction: column;
}
.chevron_open{
    transition: all 0.5s ease;
}

.chevron_close {
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.header {
    height : fit-content;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 8px;
}

.left_sec{
    display: flex;
    column-gap: 8px;
    width: 80%;
    height: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

.right_sec {
    color: #212121;
}
.property_name {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 32px;
    padding: 8px 0;
}

.hidden_sec {
    display: flex;
    row-gap: 8px;
    height: fit-content;
    width: calc(100% - 32px);
    column-gap: 8px;
    flex-wrap: wrap;
    margin-left: 32px;
}