.header {
    height: 12vh;
    position: fixed;
    top: 8vh;
    right: 0;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    justify-content: space-between;
    z-index: 10;
    background-color: white;
}

.left_sec {
    display: flex;
    height: fit-content;
    width: fit-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.left_sec  div {
    padding: 4px 10px;
} 
.selection {
    padding: 0 0 0 1rem !important;
    position: relative;
}
.dropdown {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 1rem !important;
    height: fit-content;
    width: max-content;
    position: absolute;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.dropdown li {
    list-style-type: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 0;
}
.right_sec {
    display: flex;
    align-items: center;
}

.right_sec  Button {
    padding-left: 1rem;
}

.border_image {
    box-sizing: border-box;
    border: 1px solid var(--borderColor);
    border-radius: 4px;
}