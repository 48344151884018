.sidebar {
    position: fixed;
    top: 8vh;
    left: 0;
    height: calc(100% - 8vh);
    width: fit-content;
    border-right: 1px solid var(--borderColor);
    z-index: 40;
    background-color: white;
}

.menu_item {
    width: fit-content;
    height: 3.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--blackHigh);
}


.menu_icon {
    display: inline-block;
    padding: 0 1rem;
    height: 24px;
    width: 24px;
}

.item_description {
    width: 12rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
}
.menu_item:hover{
    background: rgba(238, 42, 36, 0.08);
    box-shadow: inset 4px 0px 0px #EE2A24;
    color: #EE2A24 !important;
}

.menu_item_selected {
    width: fit-content;
    height: 3.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #EE2A24 ;
    background: rgba(238, 42, 36, 0.08);
    box-shadow: inset 4px 0px 0px #EE2A24;
}

