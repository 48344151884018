.upload_page {  
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.main_content {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    height: 92vh;
    background-color: white;
    overflow: scroll;
}

.main {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    padding: 52px 0;
}

.upload {
    width: 90%;
    height: 216px;
    box-sizing: border-box;
    border: 1px dashed var(--borderColor);
    border-radius: 4px;
    outline: 12px solid #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);
}

.label p{
    color: var(--blackHigh);
    margin: 12px 0;
}

.button_sec {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: fit-content;
    row-gap: 16px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

.button_label {
    color: rgba(0, 0, 0, 0.3);
}

.cancel_link {
    color: #2298DA;
}