.section_header {
    height: 12vh;
    position: fixed;
    top: 8vh;
    right: 0;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    justify-content: space-between;
    z-index: 10;
    background-color: white;
}

.left_sec > p {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.3px;
    color: #000000;
    padding: 0 1rem;
}

.left_sec {
    display: flex;
    align-items: center;
    column-gap: 24px;
}

.right_sec {
    display: flex;
    align-items: center;
}

.right_sec  Button {
    padding-left: 1rem;
}