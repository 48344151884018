.navbar {
    height: 8vh;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--borderColor);
    z-index: 45;
    background-color: white;
}

.left_sec {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 20px;
}

.left_sec  li {
    list-style-type: none;
    padding: 0 1rem;
}

.left_sec p {
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    line-height: 16px;
    font-weight: 600;
}

.right_sec {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.right_sec > img {
    padding-right: 1rem;
}
.right_sec .profile {
    width: fit-content;
    height: 100%;
    border-left: 1px solid var(--borderColor);
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--blackHigh);
    position: relative;
}
.profile_icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: black;
    font-weight: 600; 
    font-size : 11px;
    line-height: 16px;
    text-Align : center;
    color: white;
    padding : 4px 0;
    box-sizing : border-box;
    text-transform: capitalize;
}
.chevron_down{ 
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.chevron_up{
    transition: all 0.5s ease;
}

.logout {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    padding: 16px 24px 16px 16px;
    height: fit-content;
    width: fit-content;
    column-gap: 12px;
    top: 80%;
    right: 32px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    cursor: pointer;
}

.logout:hover {
    background-color: #F8F8F8;
}

