.property_filter {
    width: 360px;
    min-height: 240px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.04), 0px 7px 8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    position: relative;
    overflow: auto;
    max-height: 490px;
    display: flex;
    flex-direction: column;
}

.search {
    box-sizing: border-box;
    border-radius: 6px;
    height : 40px;
    padding : 8px 12px;
    color: #212121;
    position : relative;
    display : flex;
    column-gap : 16px;
    width: 328px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 12px;
}

.search_box {
    padding : 0;
    border : none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex-grow : 1;
    outline : none;
}

.option_list {
    display : flex;
    flex-direction : column;
    background-color : white;
    row-gap : 16px;
    overflow : auto;
    width : calc(100% - 32px);
    min-height : fit-content;
    max-height : 304px;
    box-sizing: border-box;
    margin: 16px;
    flex-grow: 1;
}

.no_option {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.option {
    display: flex;
    width: fit-content;
    column-gap: 16px;
    min-width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
}

.footer {
    height: 73px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    display: flex;
    padding: 16px;
    column-gap: 16px;
    align-items: center;
    background: white;
    margin-top: auto;
}

.clear {
    padding: 0 28px;
    color: #2298DA;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
}

.loading {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    padding: 16px;
}