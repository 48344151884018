.column {
    width: max-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    color : black;
    flex-grow : 1;
}

.header {
    height: 40px;
    width: 100%;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 11px;
    padding : 0 28px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
.row_item {
    height: 56px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 28px;
    display: flex;
    align-items: center;
    border-bottom : 1px solid rgba(0, 0, 0, 0.12);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
}





