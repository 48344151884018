.container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: fit-content;
    width: 472px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;
    height: fit-content;
    max-height: 400px;
    overflow: auto;
}
.crossmark {
    position: absolute;
    top: 0;
    right: -48px;
    cursor: pointer;
}

.header {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 36px;
    border-bottom: 1px solid var(--borderColor);
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.35px;
    color: #000000;
}

.sub_sec{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding : 12px 36px;
    box-sizing: border-box;
}
.subheader {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: black;
}

.notes_sec {
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    row-gap: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--blackHigh);
}

.booking_sec {
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
}

.block {
    margin-top: 16px;
    box-sizing: border-box;
    width: 50%;
}

.caption {
    margin: 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
}

.details {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}