.property_block {
    display: flex;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 12px 1rem;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    row-gap: 12px;
}

.chevron_open{
    transition: all 0.5s ease;
}

.chevron_close {
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.header {
    height : 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.left_sec{
    display: flex;
    column-gap: 8px;
    width: fit-content;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: black;
}
.property_name {
    color: rgba(0, 0, 0, 0.5);
}

.right_sec {
    display: flex;
    column-gap: 1rem;
    width: fit-content;
    height: 100%;
}

.hidden_sec {
    display: flex;
    row-gap: 8px;
    height: fit-content;
    width: 100%;
    column-gap: 8px;
    flex-wrap: wrap;
}