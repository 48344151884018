.error_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.container {
    position: absolute;
    top: 8vh;
    left: 0;
    height: 92vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_content{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    height: fit-content;
    width: max-content;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}