.bulkStaff_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.main_content {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    height: 92vh;
    background-color: white;
    overflow: scroll;
}

.main {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    padding: 32px 0;
}

.instruction {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    background-color: var(--borderColor);
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 6px;
    width: 770px;
    height: fit-content;
    overflow: hidden;
}

.block {
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
    height: max-content;
    display: flex;
    column-gap: 30px;
    align-items: flex-start;
    background-color: white;
}
.col1 {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    min-width: 42px;
}

.col2{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: fit-content;
}

.col2 p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
}