.staff_page {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

.main_content {
    position: absolute;
    top: 20vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    min-width: fit-content;
    height: 70vh;
    background-color: white;
    overflow: auto;
    
}