.main_content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: fit-content;
    width: 576px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: space-between;
}
.crossmark {
    position: absolute;
    top: 0;
    right: -48px;
    cursor: pointer;
}

.header {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 36px;
    border-bottom: 1px solid var(--borderColor);
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #000000;
}
.middle {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    padding: 32px 36px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    column-gap: 16px;
}
.footer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 36px;
    justify-content: space-between;
    border-top: 1px solid var(--borderColor);
    background-color: white;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
