.page{
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}
.main_content {
    position: absolute;
    top: 8vh;
    left: 15.5rem;
    box-sizing: border-box;
    width: calc(100% - 15.5rem);
    height: 92vh;
    background-color: white;
    overflow: scroll;
    
}
.header {
    height: fit-content;
    width: 100%;
}

.subheader {
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    justify-content: space-between;
}

.left_sec{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #000000;
    display: flex;
    align-items: center;
}
.left_sec p {
    box-sizing: border-box;
    margin: 0 16px;
}

.right_sec{
    height: fit-content;
    display: flex;
    align-items: center;
    position: relative;
}

.right_sec > img {
    height: 40px;
    width: 40px;
    border: 1px solid var(--borderColor);
    box-sizing: border-box;
    border-radius: 6px;
}

.menu {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--borderColor);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.item_container {
    width: fit-content;
    height: 100%;
    margin-left: 80px;
    display: flex;
    column-gap: 32px;
}

.menu_item {
    height: 100%;
    width: fit-content;
    box-sizing: border-box;
    padding: 8px;
    color: var(--blackHigh);
    background: #FFFFFF;
    cursor: pointer;
}
.menu_item:hover {
    color : #2298DA;
    box-shadow: inset 0px -2px 0px #2298DA;
}

.selected {
    height: 100%;
    width: fit-content;
    box-sizing: border-box;
    padding: 8px;
    color : #2298DA;
    box-shadow: inset 0px -2px 0px #2298DA;
    cursor: pointer;
}

.list {
    position: absolute;   
    z-index: 10;
    box-sizing: border-box;
    width: 276px;
    height: fit-content;
    top: 50px;
    right: -8px;
}
