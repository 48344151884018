.loader-container {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: gray;
    opacity: 0.7;
}

.loading {
    -webkit-animation: rotation 2s infinite linear;
    -moz-animation: rotation 2s infinite linear;
    -ms-animation: rotation 2s infinite linear;
    animation: rotation 2s infinite linear;
    width: 70px;
    height: 70px;
}

@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
}

@-ms-keyframes rotation {
    from {-ms-transform: rotate(0deg);}
    to {-ms-transform: rotate(359deg);}
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
}