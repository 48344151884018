.row {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}

.left_sec {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-start;
}
.left_sec > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: black;
    padding: 0 12px;
}

.right_sec {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
}
.fixed {
    width: 115px;
    height: fit-content;
    box-sizing: border-box;
}
.fixed p {
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    text-align: right;
}